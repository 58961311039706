<template>
<mdb-container fluid>
  <div class="classic-form-page">
    <mdb-view>
      <mdb-mask
        overlay=""
        class="d-flex justify-content-center align-items-center"
      >
        
        <div class="text-center">
          <h1>Permission Refusée !</h1>
          <p>vous n'avez pas les droits d'accès au contenu.</p>
          <router-link  to="/"> Tableau de bord </router-link>
        </div>
          
      </mdb-mask>
    </mdb-view>
  
  </div>
</mdb-container>
  
</template>

<script>
import {
  mdbContainer,
  mdbView,mdbMask
} from "mdbvue";
export default {

  name: "not-found",
  metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - 403',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
  components: {
    mdbContainer,
    mdbView,mdbMask
  },
  data() {
    return {

    };
  }
};
</script>

<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped>
.classic-form-page {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
}

.classic-form-page .view {
  /** background-image: url("http://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img%20(11).jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; */
  height: 100vh;
}


@media (max-width: 768px) {

}
</style>
